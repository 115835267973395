import React from 'react';
import { graphql, Link } from 'gatsby';
import Image, { FluidObject, FixedObject } from 'gatsby-image';
import SEO from '../components/SEO';
import LinkTo from '../components/LinkTo';
import Layout from '../components/Layout/Layout';
import Section from '../components/Section/Section';
import Title from '../components/Section/Title';
import Button from '../components/Button/Button';
import ShapeTitle from '../components/ShapeTitle/ShapeTitle';
import France from '../images/icons/france.inline.svg';
import HandHeart from '../images/icons/hand-heart.inline.svg';

type FluidImageObject = {
  childImageSharp: {
    fluid: FluidObject;
  };
};

type FixedImageObject = {
  childImageSharp: {
    fixed: FixedObject;
  };
};

type Queries = {
  data: {
    sun: FluidImageObject;
    poppy: FluidImageObject;
    magic: FluidImageObject;
    faces: FluidImageObject;
    cotton: FixedImageObject;
  };
};

export const query = graphql`
  query {
    sun: file(relativePath: { eq: "photos/concept/1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 463, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    poppy: file(relativePath: { eq: "photos/concept/2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 434, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    magic: file(relativePath: { eq: "photos/magic/1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    faces: file(relativePath: { eq: "photos/mini-us/1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 390, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cotton: file(relativePath: { eq: "photos/mini-us/2.png" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

const IndexPage: React.FC<Queries> = ({ data }) => (
  <Layout
    title={
      <React.Fragment>
        La poupée française et haut de gamme
        <br className="none md:block" /> à prix éthique
      </React.Fragment>
    }
  >
    <SEO
      title="Poupée Odette - Poupée française haut de gamme à prix éthique"
      description="Poupée Odette, notre poupée est 100% artisanale et fabriquée en France dans une popeline de coton toute douce et française certifiée GOTS, sans produits toxiques."
    />

    <Section>
      <div className="flex flex-wrap flex-column justify-around mw-900x mrr-auto mrb-20x mrl-auto sm:flex-row">
        <div className="mw-260x mrb-35x mrl-20x sm:mrb-0 sm:mrl-0">
          <ShapeTitle name="indexFabrication">
            <span className="mrr-8x">Fabrication française</span>
            <France className="w-12x h-12x" />
          </ShapeTitle>
          <p className="fsz-md pdl-13x">
            Saine et solide, la Poupée Odette a été dessinée et prototypée à
            Paris.
            <br />
            Elle est <strong>fabriquée à 100% en Île-de-France</strong>.
          </p>
        </div>

        <div className="self-end mw-240x mrr-30x mrb-35x sm:mrr-0 sm:self-auto sm:pdt-40x">
          <ShapeTitle name="indexStitch">Brodée à la main</ShapeTitle>
          <p className="fsz-md pdl-13x">
            <strong>Entre mère et fille avec le plus grand soin !</strong> Le
            moindre petit détail est brodé à la main dans notre atelier à Paris,
            ce qui rend chaque pièce unique.
          </p>
        </div>

        <div className="mw-250x mrb-35x mrl-30x sm:mrl-0 sm:pdt-10x">
          <ShapeTitle name="indexMaterials">Du beau et bon coton</ShapeTitle>
          <p className="fsz-md pdl-13x">
            Cousue de la tête au pied avec du{' '}
            <strong>coton biologique d’une extrême douceur</strong>, certifié{' '}
            <LinkTo
              className="current"
              address="https://www.global-standard.org"
            >
              GOTS
            </LinkTo>{' '}
            respectueux de la santé de nos enfants et de l’environnement.
          </p>
        </div>
      </div>

      <div className="flex justify-center mrr-auto mrl-auto">
        <figure className="w-465x mrr-10x">
          <Image className="mrb-5x" fluid={data.sun.childImageSharp.fluid} />
          <figcaption className="flex flex-column justify-between fw-light fsz-md sm:flex-row">
            Modèle Adèle, robe coquelicot.
          </figcaption>
        </figure>

        <figure className="w-435x pdt-40x">
          <figcaption className="flex flex-column justify-between items-center fw-light fsz-md mrb-5x sm:flex-row">
            <p className="mrb-5x sm:mrb-0">
              Modèles Rosa et Adèle, robe soleil.
            </p>
            <Button
              className="self-end sm:self-auto"
              component={Link}
              to="/shop/"
            >
              Voir
            </Button>
          </figcaption>
          <Image fluid={data.poppy.childImageSharp.fluid} />
        </figure>
      </div>
    </Section>

    <Section>
      <Title>
        ✨ Une poupée de caractère,
        <br className="md:block" /> avec des pouvoirs magiques
      </Title>
      <div className="flex flex-wrap justify-between mw-845x mrr-auto mrl-auto md:flex-nowrap">
        <div className="w-full mrb-25x md:flex-1/3 md:mw-440x">
          <Image fluid={data.magic.childImageSharp.fluid} />
        </div>
        <div className="pdr-15x pdl-15x md:flex-1 md:mw-375x">
          <div className="mrb-25x">
            <p className="fsz-md mrb-15x">
              <strong className="ff-lora fw-bold fsz-105">
                Votre poupée faite d’amour et de douceur.
              </strong>
            </p>
            <p className="fsz-md mrb-15x">
              Toutes nos poupées cachent une petite pierre précieuse près de
              leur coeur : un quartz rose qui appelle au calme et à la sérénité.
              <br />
              Nos poupées aident à accompagner les émotions de votre enfant et à
              apaiser toutes les petites tensions accumulées dans la journée.
              <br />
              Un petit câlin tout doux avec Odette et hop la magie opère !
            </p>
            <p className="fsz-md mrb-15x">
              A l’heure du coucher, Odette joue à merveille son rôle de super
              amie, elle réconforte et veille pour une nuit paisible.
            </p>
            <p className="fsz-md mrb-15x">
              Au matin, elle accompagne votre boutchou dans toutes ses aventures
              et à devenir ce qu’elle / il a envie d’être chaque jour !
            </p>
          </div>
          <Button component={Link} to="/shop/">
            Voir
          </Button>
        </div>
      </div>
    </Section>

    <Section>
      <div className="lg:flex">
        <div className="justify-center items-center md:flex lg:flex-1 lg:block lg:mw-285x lg:mrr-25x">
          <h1
            id="notre-demarche-ethique"
            className="blue ff-lora fsz-xlg fw-regular ta-left pdl-15x mrb-50x md:mrr-15x md:mrb-0 lg:mrr-auto lg:mrb-20x lg:mrl-auto lg:pdt-50x"
          >
            Agir <br />
            pour nos <br />
            mini-nous
          </h1>

          <div className="flex items-center mrb-15x md:mrb-0 md:mw-480x">
            <div className="flex-1 pdr-15x pdl-15x fsz-md">
              <p className="mrb-15x">
                <strong>
                  Une fabrication locale, raisonnée avec une sélection
                  rigoureuse des matières.
                </strong>
              </p>
              <p>
                12 heures de patience, de douceur et d’amour sont nécessaires
                pour créer la Poupée Odette.
              </p>
            </div>
            <Image
              className="flex-1 pdr-15x pdl-15x fsz-md md:none"
              fluid={data.faces.childImageSharp.fluid}
            />
          </div>
        </div>

        <div className="block md:flex lg:flex-2">
          <div className="w-full mw-530x mrr-auto mrl-auto ta-center md:mw-390x md:mrr-25x md:ta-right">
            <ShapeTitle
              name="indexEngagment"
              className="inline-block md:mrr-50x"
            >
              Poupée Odette,
              <br />
              une marque engagée.
            </ShapeTitle>
            <Image
              className="none md:block"
              fluid={data.faces.childImageSharp.fluid}
            />
          </div>
          <div className="pdr-15x pdl-15x mrr-auto mrl-auto md:z-10 md:mrt-115x md:mw-530x">
            <div className="fsz-md mrb-30x">
              <p className="lh-1/2 mrb-15x">
                C’est en cherchant une belle poupée pour le dernier de la
                famille que nous avons réalisé, ma Maman et moi, qu’il était
                impossible de trouver de belles poupées françaises, éthiques qui
                ne soient ni dangereuses pour la santé des enfants, ni pour
                l'environnement. À prix éthique !
              </p>
              <p className="lh-1/2 mrb-15x">
                Nous avons donc décidé d’offrir une alternative ! Notre premier
                modèle Adèle, pensé pour repartir sur le droit chemin :) et vous
                offrir une poupée aussi douce pour nos enfants que pour notre
                planète.
              </p>
              <p className="lh-1/2">
                Pour voir notre politique de prix juste, rendez-vous sur{' '}
                <LinkTo
                  className="current"
                  address="https://www.instagram.com/p/B4nJwSLKYDy/"
                >
                  ce post Instagram.
                </LinkTo>
              </p>
            </div>
            <Button component={Link} to="/shop/">
              Voir
            </Button>
            <div className="flex relative items-end mrr-auto mrl-auto w-235x h-175x">
              <div className="absolute z-10 top-0 -right-10x">
                <Image fixed={data.cotton.childImageSharp.fixed} />
              </div>
              <div className="relative z-100 -mrl-30x">
                <HandHeart className="mrl-30x" />
                <p className="fsz-md pink">
                  Notre{' '}
                  <strong>
                    coton naturel, non
                    <br /> traité, labelisé GOTS
                  </strong>{' '}
                  pour le
                  <br /> rembourrage des poupées
                  <br /> Odette.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>

    <Section>
      <div className="ta-center">
        <ShapeTitle name="indexAmbition">Notre ambition</ShapeTitle>
        <Title className="mw-825x">
          Devenir la référence de la poupée artisanale, haut de gamme et
          fabriquée en France !
        </Title>
      </div>
      <div className="pdr-15x pdl-15x justify-center md:flex">
        <div className="mrr-auto mrl-auto md:flex-1 md:mw-330x md:mrr-45x md:mrl-0">
          <p className="fsz-md lh-1/2 mrb-20x">
            <strong>
              Une création originale, l’obsession du moindre détail et la
              recherche des plus beaux tissus éthiques et sains.
            </strong>
            <br />
            13 mois de travail ont été nécessaires pour créer la Poupée Odette.
          </p>
          <p className="fsz-md lh-1/2 mrb-20x">
            100% fabriquée en France, à 4 mains entre mère et fille. Et lorsque
            nous avons des pics de commande, nous collaborons avec un atelier de
            réinsertion sociale majoritairement féminin en Île-de-France qui
            nous aide à concevoir une partie de nos jolies poupées et vêtements.
            Ce partenariat nous tient particulièrement à coeur.
          </p>
        </div>
        <div className="mrr-auto mrl-auto md:flex-1 md:mw-330x md:mrr-0 md:mrl-0">
          <p className="fsz-md lh-1/2 mrb-20x">
            Nous mettons tout notre amour dans nos créations, cela rend
            certainement nos chères Poupées magiques !
            <br />
            Capables d’amuser, de jouer, de rassurer, de câliner et de vivre
            toutes les émotions avec votre enfant !
          </p>
          <p className="fsz-md lh-1/2 mrb-20x">
            <strong>
              Poupée Odette est née pour offrir aux enfants un compagnon de vie
              d’une qualité irréprochable, qui l’accompagne dans toutes ses
              aventures.
            </strong>
          </p>
          <Button component={Link} to="/shop/">
            Voir
          </Button>
        </div>
      </div>
    </Section>
  </Layout>
);

export default IndexPage;
